import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {FileHandle} from "../../../../../../../../core/ek-e-commerce/ek-models/file-handle.model";
import {DocumentModel} from "../../../../../../../../core/ek-e-commerce/ek-models/document.model";
import {DocumentService} from "../../../../../../../../core/services/document.service";
import {BehaviorSubject} from "rxjs";
import {ImageViewerConfig} from "ngx-image-viewer";

@Component({
    selector: 'kt-document-display',
    templateUrl: './document-display.component.html',
    styleUrls: ['./document-display.component.scss']
})
export class DocumentDisplayComponent implements OnInit {
    @Input() document: DocumentModel;
    @Input() folderState: string = '';

    url: any;
    private imagePath$ = new BehaviorSubject<SafeUrl>(null);
    public imagePathObs$ = this.imagePath$.asObservable();

    private loading$ = new BehaviorSubject<boolean>(false);
    public loadingObs$ = this.loading$.asObservable();

    currentRole: string = '';
    isIMg = false;
    config: ImageViewerConfig;

    constructor(private sanitizer: DomSanitizer,
                private documentService: DocumentService,
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }


    ngOnInit(): void {

        this.config = {
            btnClass: 'custom-button-class'
        };


        this.documentService.currentDocSbj$.subscribe(document => {
            if (document) {
                this.isJPEG(document.documentUrl);
                this.document = document;
                let tempImagePath = this.cleanUrl(this.document.documentUrlPreSigned);
                this.imagePath$.next(tempImagePath);
            }
        });


    }

    cleanUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    cleanUrl2(file) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(file));
    }


    public isJPEG(url): void {
        this.isIMg = !!(url?.match('png') || url?.match('jpg') || url?.match('jpeg') || url?.match('PNG') || url?.match('JPG') || url?.match('JPEG'));
    }

    canChangeDoc() {
        
        switch (this.currentRole) {

            case 'ROLE_BANKER_EK' :
                return false;

            case 'ROLE_COMMERCIAL_REGION_MANAGER' :
                return false;

            case 'ROLE_CONFORMITY_MANAGER' :
                return false;

            case 'ROLE_GUEST_ADMIN' :
                return false;

            case 'ROLE_CREDIT_ANALYST_EK' :
                return !(this.folderState !== 'EK_BANK_RESERVE'
                    && this.folderState !== 'EK_FOLDER_CREATED'
                    && this.folderState !== 'EK_FOLDER_IN_PROGRESS'
                    && this.folderState !== 'EK_GUARTENTEES_PENDING_SIGNATURE'
                    && this.folderState !== 'EK_GUARTENTEES_SIGNED_IN_PROGRESS');

            case 'ROLE_POS_EK' :
            case 'ROLE_COMMERCIAL_POS_EK' :
                return !(this.folderState !== 'EK_BANK_RESERVE'
                    && this.folderState !== 'EK_FOLDER_CREATED');

            default :
                return true;
        }

    }

    onFileSelected(event: any) {
        if (event.target.files) {
            const file: File = event.target.files[0];
            if (
                file.type === 'image/png' ||
                file.type === 'image/jpg' ||
                file.type === 'image/jpeg'
            ) {
                const fileHandle: FileHandle = {
                    file: file,
                    url: this.sanitizer.bypassSecurityTrustResourceUrl(
                        window.URL.createObjectURL(file)
                    ),
                };
                this.imagePath$.next(fileHandle.url);
            } else if (file.type === 'application/pdf') {
                let url = this.cleanUrl2(file);
                this.imagePath$.next(url);
            }
            this.updateFile(file);
        }
    }

    updateFile(file: File) {
        this.loading$.next(true);

        this.documentService.updateDocument(file, this.document).subscribe({
            next: () => {
                this.loading$.next(false);
            },
            error: () => {
                this.loading$.next(false);
            }
        });
    }

    fileDropped(event: any) {
        const file: File = event.file;
        if (
            file.type === 'image/png' ||
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg'
        ) {
            const fileHandle: FileHandle = {
                file: file,
                url: this.sanitizer.bypassSecurityTrustResourceUrl(
                    window.URL.createObjectURL(file)
                ),
            };
            let imagePath = fileHandle.url;
            this.imagePath$.next(imagePath);

        } else if (file.type === 'application/pdf') {
            let imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(
                window.URL.createObjectURL(file));
            this.imagePath$.next(imagePath);
        }

        this.updateFile(event.file);
    }


}
