import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild,} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';

import {DocumentModel} from '../../../../../../core/ek-e-commerce/ek-models/document.model';
import {FileHandle} from '../../../../../../core/ek-e-commerce/ek-models/file-handle.model';
import {DocumentService} from '../../../../../../core/services/document.service';
import {BehaviorSubject} from 'rxjs';
import {FoldersService} from '../../../../../../core/ek-e-commerce/ek-services/folders.service';
import {EkSnackBarService} from '../../../../../../core/services/ek-snackBar.service';
import {MatDialog} from '@angular/material/dialog';
import {VerifyOcrDialogComponent} from '../../folders/verify-ocr-dialog/verify-ocr-dialog.component';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';

interface openDate {
    idDoc: number;
    date: string;
}

@Component({
    selector: 'app-files-card',
    templateUrl: './files-card.component.html',
    styleUrls: ['./files-card.component.scss'],
})
export class FilesCardComponent implements OnInit, OnChanges {

    @ViewChild('selectFile', {static: false}) selectFileInput!: ElementRef<HTMLInputElement>;

    @Input() fileImage!: string;
    @Input() image?: File;
    @Input() imagePath!: string | SafeUrl;
    @Input() cardTitle = '';
    @Input() cardTitleUpload = '';
    @Input() cardType = '';
    @Input() cardTitleAr = '';
    @Input() isValid: boolean | undefined = true;
    @Input() right_document: boolean | undefined = true;
    @Input() cardId!: number;
    @Input() clientId!: number;
    // @Input() documentId!: number;
    @Input() documentsState = '';
    @Output() rollback: EventEmitter<boolean> = new EventEmitter();


    loadingSubject$ = new BehaviorSubject<boolean>(false);
    readonly loading$ = this.loadingSubject$.asObservable();

    loading = false;
    folderId!: number;
    skeleton = 'assets/media/icons/backup.svg';
    files: File[] = [];
    isImg = true;
    fileName?: string;
    idOrder = 0;
    isChecked: boolean;
    isCheckSbj$ = new BehaviorSubject<boolean>(false);
    isCheckObs$ = this.isCheckSbj$.asObservable();

    canSee: boolean;
    canUpdate: boolean;
    currentRole = '';
    currentStep = '';
    step: any;

    openDateObj: openDate[] = [];

    mustDoOCRExtraction = false;
    canOpenOcrVerifyPopUp = false;


    constructor(
        private sanitizer: DomSanitizer,
        private documentService: DocumentService,
        private route: ActivatedRoute,
        private layoutUtilsService: LayoutUtilsService,
        private router: Router,
        private dialog: MatDialog,
        private folderService: FoldersService,
        private EkSnackBar: EkSnackBarService // private accountService: AccountService
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params: any) => {
            this.folderId = Number(params.get('idFolder'));
            this.idOrder = Number(params.get('idOrder'));
        });

        this.folderService.isCheckSbj$.subscribe((isChecked) => {
            this.isCheckSbj$.next(isChecked);
            this.isChecked = isChecked;
        });

        this.route.queryParams.subscribe((params: any) => {
            this.currentStep = String(params.step);
            this.step = params.step;
            if (params.step === '2') {
                this.canSee = true;
            } else {
                this.canSee = false;
            }
        });

    }

    canChangeDoc() {
        const folderState = this.folderService.folderStateSubj$.value;

        this.route.queryParams.subscribe((params) => {
            if (params.step !== 2) {
                return true;
            } else {
                switch (this.currentRole) {
                    case 'ROLE_BANKER_EK':
                        return false;
                        break;

                    case 'ROLE_CREDIT_ANALYST_EK':
                        if (
                            folderState !== 'EK_BANK_RESERVE' &&
                            folderState !== 'EK_FOLDER_CREATED' &&
                            folderState !== 'EK_FOLDER_IN_PROGRESS' &&
                            folderState !== 'EK_GUARTENTEES_PENDING_SIGNATURE' &&
                            folderState !== 'EK_GUARTENTEES_SIGNED_IN_PROGRESS'
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                        break;
                    case 'ROLE_POS_EK':
                    case 'ROLE_COMMERCIAL_POS_EK':
                        if (
                            folderState !== 'EK_BANK_RESERVE' &&
                            folderState !== 'EK_FOLDER_CREATED'
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                        break;

                    default:
                        return true;
                        break;
                }
            }
        });
    }

    canPDVAChangeDoc() {

        const folderState = this.folderService.folderStateSubj$.value;

        if (this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') {

            // if GUARANTEES, pos can upload docs only if status is EK_GUARTENTEES_PENDING_SIGNATURE
            if ((this.cardType === 'GUARANTEES' ||  this.cardType === 'INVOICE') &&
                folderState === 'EK_GUARTENTEES_PENDING_SIGNATURE') {
                return true;
            }

            return folderState === 'EK_BANK_RESERVE' || folderState === 'EK_FOLDER_CREATED';

        }

        return true;
    }

    ngOnChanges() {
        if (this.imagePath) {
            if (
                !String(this.imagePath).includes('PDF') &&
                !String(this.imagePath).includes('pdf')
            ) {
                this.isImg = true;
            } else {
                this.isImg = false;

                this.fileName = `${this.cardTitle}.pdf`;
            }
        }
    }

    onImgClick() {
        if (this.isImg && (this.imagePath || this.image)) {
            if (this.imagePath) {
                window.open(String(this.imagePath), '_blank');
            } else if (this.image) {
                window.open(String(this.imagePath), '_blank');
            }
        } else {
            return;
        }
    }

    onFileSelected(event: any) {
        if (event.target.files) {
            const file: File = event.target.files[0];
            this.image = event.target.files[0];
            if (!this.imagePath) {
                if (
                    file.type === 'image/png' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/jpeg'
                ) {
                    this.isImg = true;
                    const fileHandle: FileHandle = {
                        file,
                        url: this.sanitizer.bypassSecurityTrustUrl(
                            window.URL.createObjectURL(file)
                        ),
                    };
                    this.loading = false;
                    this.loadingSubject$.next(false);
                    this.imagePath = fileHandle.url;
                } else if (file.type === 'application/pdf') {
                    this.isImg = false;
                    this.fileName = file.name;
                    this.loading = false;
                    this.loadingSubject$.next(false);
                    this.imagePath = this.sanitizer.bypassSecurityTrustUrl(
                        window.URL.createObjectURL(file)
                    );
                }
                this.uploadFile(file);
            } else {
                if (
                    file.type === 'image/png' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/jpeg'
                ) {
                    this.isImg = true;
                    const fileHandle: FileHandle = {
                        file,
                        url: this.sanitizer.bypassSecurityTrustUrl(
                            window.URL.createObjectURL(file)
                        ),
                    };
                    this.imagePath = fileHandle.url;
                    this.loading = false;
                    this.loadingSubject$.next(false);
                } else if (file.type === 'application/pdf') {
                    this.isImg = false;
                    this.fileName = file.name;
                    this.loading = false;
                    this.loadingSubject$.next(false);
                    this.imagePath = this.sanitizer.bypassSecurityTrustUrl(
                        window.URL.createObjectURL(file)
                    );
                }
                this.updateFile(file);
            }
        }
    }

    isFileDropped(event: any) {


        if (this.isCheckSbj$.value === true && (this.cardType === 'CIVILIAN_STATE' || this.cardType === 'PROFESSIONAL')
            && (this.currentRole === 'ROLE_SUPERADMIN'
                || this.currentRole === 'ROLE_ADMIN'
                || this.currentRole === 'ROLE_CREDIT_ANALYST_EK'
                || this.currentRole === 'ROLE_POS_EK'
                || this.currentRole === 'ROLE_COMMERCIAL_POS_EK')) {
            // startAutomaticExtraction
            this.canOpenOcrVerifyPopUp = true;
            this.openOCRDialogConfirmation(true, event);
            return;
        } else {
            this.fileDropped(event);
        }

    }

    fileDropped(event: any) {
        const folderState = this.folderService.folderStateSubj$.value;

        // if role is different then banker or POS with folder state from EK_GUARTENTEES_PENDING_SIGNATURE and step = 6, let him upload img ...
        if (
            this.currentRole === 'ROLE_BANKER_EK'
            || this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER'
            || this.currentRole === 'ROLE_CONFORMITY_MANAGER'
            || (this.currentStep !== '6' &&
                (this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') &&
                folderState !== 'EK_FOLDER_CREATED' &&
                folderState !== 'EK_FOLDER_RESERVE') ||
            (this.currentStep !== '6' &&
                this.currentRole === 'ROLE_CREDIT_ANALYST_EK' &&
                folderState !== 'EK_BANK_RESERVE' &&
                folderState !== 'EK_FOLDER_IN_PROGRESS') ||
            (this.currentStep === '6' &&
                (this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') &&
                folderState !== 'EK_GUARTENTEES_PENDING_SIGNATURE') ||
            (this.currentStep === '6' &&
                this.currentRole === 'ROLE_CREDIT_ANALYST_EK' &&
                folderState !== 'EK_GUARTENTEES_PENDING_SIGNATURE'
                || this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER'
                || this.currentRole === 'ROLE_CONFORMITY_MANAGER'
                || this.currentRole === 'ROLE_GUEST_ADMIN')
        ) {
            this.loadingSubject$.next(false);
            return;
        }

        const file: File = event.file;

        if (!this.imagePath) {

            if (
                file.type === 'image/png' ||
                file.type === 'image/jpg' ||
                file.type === 'image/jpeg'
            ) {
                this.isImg = true;
                const fileHandle: FileHandle = {
                    file,
                    url: this.sanitizer.bypassSecurityTrustUrl(
                        window.URL.createObjectURL(file)
                    ),
                };
                this.imagePath = fileHandle.url;
            } else if (file.type === 'application/pdf') {
                this.isImg = false;
                this.fileName = file.name;
                this.imagePath = this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(file)
                );
            }

            this.uploadFile(event.file);
        } else {

            if (
                file.type === 'image/png' ||
                file.type === 'image/jpg' ||
                file.type === 'image/jpeg'
            ) {
                this.isImg = true;
                const fileHandle: FileHandle = {
                    file,
                    url: this.sanitizer.bypassSecurityTrustUrl(
                        window.URL.createObjectURL(file)
                    ),
                };
                this.imagePath = fileHandle.url;
            } else if (file.type === 'application/pdf') {
                this.isImg = false;
                this.fileName = file.name;
                this.imagePath = this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(file)
                );
            }

            this.updateFile(event.file);
        }

    }

    uploadFile(file: File) {
        this.loading = true;
        this.loadingSubject$.next(true);
        const document: DocumentModel = {
            documentsGender: this.cardTitleUpload,
            documentsType: this.cardType,
            folderId: this.folderId,
        };
        if (this.router.url === '/account/files') {
            this.documentService.uploadProfileDocument(file, document).subscribe({
                next: () => {
                    this.loading = false;
                    this.loadingSubject$.next(false);
                },
                error: () => {
                    this.loading = false;
                    this.imagePath = '';
                    this.image = null as any;
                    this.fileName = null as any;
                    this.isImg = true;
                    const msg =
                        'Connexion avec le serveur a échoué, veuillez réessayer à nouveau';
                    this.EkSnackBar.openSnackBar(msg, '', 'error-snackbar');
                },
            });
        } else {
            this.documentService.uploadDocument(file, document).subscribe({
                next: (res) => {
                    this.loading = false;
                    this.loadingSubject$.next(false);
                    this.cardId = res.body.id;
                    this.verifyOcr();
                },
                error: () => {
                    this.loading = false;
                    this.imagePath = '';
                    this.image = null as any;
                    this.fileName = null as any;
                    this.isImg = true;
                    const msg =
                        'Connexion avec le serveur a échoué, veuillez réessayer à nouveau';
                    this.EkSnackBar.openSnackBar(msg, '', 'error-snackbar');
                },
            });
        }
    }

    updateFile(file: File) {
        this.loading = true;
        this.loadingSubject$.next(true);
        const document: DocumentModel = {
            documentsGender: this.cardTitleUpload,
            documentsType: this.cardType,
            folderId: this.folderId,
            id: this.cardId,
        };
        if (this.router.url === '/account/files') {
            this.documentService.updateProfileDocument(file, document).subscribe({
                next: () => {
                    this.loading = false;
                    this.loadingSubject$.next(false);
                },
                error: () => {
                    this.loading = false;
                    this.loadingSubject$.next(false);
                    this.imagePath = '';
                    this.image = null as any;
                    this.fileName = null as any;
                    this.isImg = true;
                    const msg =
                        'Connexion avec le serveur a échoué, veuillez réessayer à nouveau';
                    this.EkSnackBar.openSnackBar(msg, '', 'error-snackbar');
                },
            });
        } else {
            this.documentService.updateDocument(file, document).subscribe({
                next: (res) => {
                    this.loading = false;
                    this.loadingSubject$.next(false);
                    this.cardId = res.body.id;
                    this.verifyOcr();

                },
                error: () => {
                    this.loading = false;
                    this.loadingSubject$.next(false);
                    const msg =
                        'Connexion avec le serveur a échoué, veuillez réessayer à nouveau';
                    this.EkSnackBar.openSnackBar(msg, '', 'error-snackbar');
                },
            });
        }
    }

    openFileInput() {
        if (this.isCheckSbj$.value === true
            && (this.cardType === 'CIVILIAN_STATE'
                || this.cardType === 'PROFESSIONAL')
            && (this.currentRole === 'ROLE_SUPERADMIN'
            || this.currentRole === 'ROLE_ADMIN'
            || this.currentRole === 'ROLE_CREDIT_ANALYST_EK'
            || this.currentRole === 'ROLE_POS_EK'
            || this.currentRole === 'ROLE_COMMERCIAL_POS_EK')) {
            // startAutomaticExtraction
            this.canOpenOcrVerifyPopUp = true;
            this.openOCRDialogConfirmation();
            return;
        } else {
            this.selectFileInput.nativeElement.click();
        }
    }

    openOCRDialogConfirmation(isFileDropped?: boolean, event?: any) {

        const dialogRef = this.dialog.open(VerifyOcrDialogComponent, {
                width: '480PX',
                data: {
                    folderId: this.folderId
                }
            }
        );

        dialogRef.afterClosed().subscribe(feedBack => {
            if (feedBack !== null && feedBack !== undefined && feedBack !== '') {
                this.mustDoOCRExtraction = Boolean(feedBack);
                if (isFileDropped) {
                    this.fileDropped(event);
                    return;
                } else {
                    this.selectFileInput.nativeElement.click();
                }
            }
        });
    }

    goToChecklist(cardId: number) {
        // get date of opening doc ....

        const localDateObj = JSON.parse(localStorage.getItem('docsOpenedAt'));

        this.openDateObj = localDateObj ? localDateObj : [];

        const date = new Date();

        const formattedDate = date.toISOString().replace('T', ' ').slice(0, -1);

        if (this.openDateObj) {
            const itemExists = this.openDateObj.some((obj) => obj.idDoc === cardId);

            // new open doc
            if (!itemExists) {
                this.openDateObj.push({idDoc: cardId, date: formattedDate});
            }

            localStorage.setItem('docsOpenedAt', JSON.stringify(this.openDateObj));

            this.router.navigate(['checklist', cardId], {relativeTo: this.route});
        }
    }

    getBackGroundColor(state: string) {
        switch (state) {
            case 'ACCEPTED':
                return 'var(--eki-green)';
                break;
            case 'REFUSED':
                return 'var(--ek--cherry-red)';
                break;
            case 'PENDING':
                return 'var(--ek-shade-sh-light-07)';
                break;
        }
    }

    verifyOcr() {
        if (this.canOpenOcrVerifyPopUp) {
            this.documentService.verifyOcr(this.folderId, this.mustDoOCRExtraction).subscribe({
                next: (res) => {
                    if (res.body !== undefined && res.body !== null) {
                        this.folderService.isCheckSbj$.next(!this.mustDoOCRExtraction);
                    }
                },
                error: () => {
                    const message = `un erreur survenue, réessayez encore s'il vous plaît!`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Create,
                        5000,
                        false,
                        false
                    );
                }
            });
        }
    }
}
