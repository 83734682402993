import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CommentFolderModel} from "../../../../../../core/ek-e-commerce/ek-models/CommentFolder.model";
import {ConfigurationsDatasource} from "../../../../../../core/ek-e-commerce/ek-data-sources/configuration.datasource";
import {BehaviorSubject, Observable, of} from "rxjs";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Folder} from "../../../../../../../../../ek-guichet/src/app/Models/folder";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CommentFolderService} from "../../../../../../core/ek-e-commerce/ek-services/comment-folder.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {FoldersService} from "../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {AddCommentComponent} from "../folder-steps/folder-step-eight/add-comment/add-comment.component";
import {Role} from "../../../../../../core/auth";

@Component({
    selector: 'kt-comments-pop-up',
    templateUrl: './comments-pop-up.component.html',
    styleUrls: ['./comments-pop-up.component.scss']
})
export class CommentsPopUpComponent implements OnInit {
    pageSize = 10;
    pageIndex = 1;
    page: number = 1;
    comments: CommentFolderModel[] = [];

    idOrder = 0;
    idFolder = 0;

    dataSource: ConfigurationsDatasource;
    totalComments = 100;
    currentPageIndex = 0;
    test = false;
    index: number;
    commentSubject$ = new BehaviorSubject<CommentFolderModel[]>([]);
    readonly CommentsObj$ = this.commentSubject$.asObservable();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    title: any;
    role: string [] = [];
    currentRole: string = '';
    folder: Folder;
    user: any;


    constructor(public dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: { folderId: number , requestNumber:string },
                public dialogRef: MatDialogRef<CommentsPopUpComponent>,
                private commentService: CommentFolderService,
                private route: ActivatedRoute,
                private router: Router,
                private location: Location,
                private folderService: FoldersService,
                private layoutUtilsService: LayoutUtilsService) {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.currentRole = this.user.roles;
    }

    ngOnInit(): void {

        this.idFolder = this.data.folderId;
        this.role = JSON.parse(localStorage.getItem('currentUser')).roles;
        this.title = this.route.snapshot.data['title'];

        this.commentSubject$.subscribe(change => {
            this.comments = change.sort((a, b) => b.id - a.id);
        });

        this.getComments();

        this.folderService.getFolderById(this.idFolder).subscribe({
            next: (folder) => {
                this.folder = folder;
            },
            error: () => {
                console.log('error in getting folder by id ...')
            }
        });
    }


    getComments() {
        this.commentService.getAllComments(this.idFolder).subscribe(data => {
            this.comments = data.content.sort((a, b) => b.id - a.id);
            this.commentSubject$.next(data.content);
        });
    }

    isPossibleToAddComment() {

        if (this.currentRole === 'ROLE_CONFORMITY_MANAGER'
            || this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER'
            || this.currentRole === 'ROLE_GUEST_ADMIN') {
            return false;
        }

        if (this.currentRole === 'ROLE_CREDIT_ANALYST_EK') {
            return (this.folder?.folderState === 'EK_BANK_RESERVE'
                || this.folder?.folderState === 'EK_FOLDER_IN_PROGRESS'
                || this.folder?.folderState === 'EK_FOLDER_CREATED');
        }

        if (this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') {
            return (this.folder?.folderState === 'EK_FOLDER_CREATED'
                || this.folder?.folderState === 'EK_FOLDER_RESERVE');
        }

        return true;
    }

    isPossibleToEditComment(comment: CommentFolderModel) {

        if (this.currentRole === 'ROLE_CONFORMITY_MANAGER'
            || this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER'
            || this.currentRole === 'ROLE_GUEST_ADMIN') {
            return false;
        }

        if (this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') {
            return ((comment.email === this.user.email) && (this.folder.folderState === 'EK_FOLDER_CREATED' || this.folder.folderState === 'EK_FOLDER_RESERVE'))
        }

        if (this.currentRole === 'ROLE_CREDIT_ANALYST_EK') {
            return ((comment.email === this.user.email) && (this.folder.folderState === 'EK_BANK_RESERVE' || this.folder.folderState === 'EK_FOLDER_IN_PROGRESS'));
        }


        if (this.currentRole === 'ROLE_BANKER_EK') {
            return comment.email === this.user.email;
        }

        return true;
    }

    isPossibleToDeleteComment() {

        if (this.currentRole === 'ROLE_SUPERADMIN'
            || this.currentRole === 'ROLE_ADMIN' ) {
            return true;
        }

        return false;

    }

    translateRole(role) {
        switch (role) {
            case 'ROLE_SUPERADMIN':
                return 'Super Administrateur';
                break;
            case 'ROLE_ADMIN':
                return 'Administrateur';
                break;
            case 'ROLE_POS_EK':
                return 'POS (Point de vente)';
                break;
            case 'ROLE_CREDIT_ANALYST_EK':
                return 'Credit Analyst';
                break;
            case 'ROLE_BANKER_EK':
                return 'Banquier';
                break;
            case 'ROLE_COMMERCIAL_POS_EK':
                return  'Commercial';
                break;
            default:
                return '/'
                break;
        }
    }

    pageChanged(event: PageEvent) {
        this.pageSize = event.pageSize;
        this.currentPageIndex = event.pageIndex;
    }

    openDialogEdit(comment: CommentFolderModel) {

        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    comment: comment,
                    editMode: true,
                    idFolder: this.idFolder,
                    title: this.title,
                    finalCheck: '',
                    documentGender: '',
                    documentTitle: '',
                    documentId: '',
                    state: '',
                    folderState: this.folder.folderState,
                }
            }
        );

        dialogRef.afterClosed().subscribe(result => {

            if (result) {

                const index = this.comments.findIndex(cmnt => cmnt.id === comment.id);

                this.comments[index] = result;

                const sortedComments = this.comments.sort((a, b) => b.id - a.id);

                const message = `commentaires modifiés avec succès !`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
                this.commentSubject$.next(sortedComments);
            }
        });


    }


    openDialog(): void {
        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    editMode: false,
                    idFolder: this.idFolder,
                    title: this.title,
                    finalCheck: '',
                    documentGender: '',
                    documentTitle: '',
                    documentId: '',
                    state: '',
                    folderState: this.folder.folderState,
                }
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.id) {

                this.comments.push(result);

                const sortedComments = this.comments.sort((a, b) => b.id - a.id);

                this.commentSubject$.next(sortedComments);

                const message = `commentaires ajoutés avec succès !`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );

            }

        });
    }

    deleteComment(id: number) {

        this.commentService.deleteCommentById(id).subscribe(data => {
            if (data.code == 0) {
                this.test = true;
                this.layoutUtilsService.showActionNotification('Le commentaire a été supprimé avec succès');
            }
        });
        this.comments = this.comments.filter(comment => comment.id !== id);
        this.comments = this.comments.sort((a, b) => b.id - a.id);
        this.commentSubject$.next(this.comments);

    }


}
