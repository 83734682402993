import {BaseModel} from "../../_base/crud";

type FileShippingType = 'POINT_OF_SALE' | 'RELAY_POINT' | 'RESIDENCE';
type CreatorType = 'CLIENT' | 'ADMIN' | 'POS' | 'COMMERCIAL';

export class ListingModel extends BaseModel {
    id:number;
    folderId:number;
    phoneClient: string;
    firstNameClient:string;
    lastNameClient:string;
    fileSippingType: FileShippingType;
    state:string;
    folderState:string;
    folderPreviousState:string;
    wilaya: string;
    cancellingReason:string;
    updaterFirstName:String;
    updaterLastName:String;
    firstName:string;
    lastName:string;
    archived: boolean;
    username: string;
    requestNumber: string;
    commentsCount:number;
    creatorType:CreatorType;
    role:string;

}
